import { useCallback, useState, useRef, useEffect } from "react";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
  Controls,
  Background,
  BackgroundVariant,
} from "reactflow";
import "reactflow/dist/style.css";
import NodeContainer from "../DragInputs";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import CustomEdge from "../CustomEdge";
import ConnectionLine from "../../ConnectLine";
import {
  FaFacebookMessenger,
  FaImage,
  FaListAlt,
  FaInfo,
  FaRegPaperPlane,
} from "react-icons/fa";
import ListDrag from "../ListDrag";
import FlowKey from "../FlowKey";
import Location from "../FlowLocation";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { FaMobileScreenButton } from "react-icons/fa6";
import textInfoImage from "../../../assests/Textimage.png";
import PopupProductModal from "../ReactToolTip/PopupProductModal";
import { AiFillProduct } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { SiGoogleforms } from "react-icons/si";
import { FaGoogle } from "react-icons/fa";
import { IoTime } from "react-icons/io5";
import replayimage from "../../../assests/IBR.png";
import cta from "../../../assests/cta.png";
import List from "../../../assests/List.png";
import List2 from "../../../assests/List2.png";
import allmedia from "../../../assests/allMedia.png";
import form from "../../../assests/Form.png";

//-----------------------------
import ActionUrlButton from "./UpdateActionURLbutton";
import TextAreaComponent from "./UpdateTextAreaComponent";
import MediaNode from "./UpdateMediaNode";
import InteractiveList from "./UpdateInteractiveList";
import AllMedia from "./UpdateAllmedia";
import PopupModal from "../ReactToolTip/PopupModal";
import CatalogNode from "./UpdateCatelogNode";
import SingleProductNode from "./UpdateSingleProductNode";
import MultiProductListNode from "./UpdateMultiMediaList";
import PopupCarouselModal from "../ReactToolTip/PopupCarouselModal";
import PaymentGatewayNode from "./UpdatePaymentGatewayNode";
import PaymentOrderDetailsNode from "./UpdatePaymentOrderDetails";
import PopupPaymentModal from "../ReactToolTip/PopupPaymentModal";
import PopupPaymentCarousel from "../ReactToolTip/PopupPaymentCarousel";
import Formdata from "./UpdateFormData";
import GoogleFormComponent from "./UpdateGoogleForm";
import TimeDelayComponent from "./UpdateTimeDelay";
import PopupModalMedia from "../ReactToolTip/PopupModalMedia";
import PopupModalCTA from "../ReactToolTip/PopupModalCTA";
import PopupModalList from "../ReactToolTip/PopupModalList";
import PopupModalAllMedia from "../ReactToolTip/PopupModalAllMedia";
import PopupModalForm from "../ReactToolTip/PopupModalForm";
//------------------------------

const rfStyle = {
  backgroundColor: "white",
  padding: 10,
  color: "balck",
};

// Initialize with an empty array

const nodeTypes = {
  nodeContainer: NodeContainer,

  listDrag: ListDrag,
  flowKey: FlowKey,
  location: Location,

  interactiveCallToActionURLButtonMessages: ActionUrlButton,
  text: TextAreaComponent,
  interactiveReplyButtonsMessages: MediaNode,
  interactiveListMessages: InteractiveList,
  media: AllMedia,
  catalogMessages: CatalogNode,
  singleProductMessage: SingleProductNode,
  multiProductMessage: MultiProductListNode,
  paymentsviaPaymentGateways: PaymentGatewayNode,
  paymentOrderDetailsNode: PaymentOrderDetailsNode,
  flowMessages: Formdata,
  googleForm: GoogleFormComponent,
  timeDelay: TimeDelayComponent,
};
const edgeTypes = {
  customEdge: CustomEdge, // Match the type here with the one you're using in addEdge
};

function Flow() {
  const location = useLocation();
  const responseData = location.state?.responseData;
  const baseUrl = process.env.REACT_APP_API_URL;
  console.log("envurl", baseUrl);
  console.log("working ", JSON.stringify(responseData));
  const phoneNumberId = responseData.phone_number_id;
  console.log("phoneId", phoneNumberId);
  const a_token = localStorage.getItem("a_token");
  const token = btoa(a_token);
  const Phonumbernames = btoa(phoneNumberId);
  console.log("parameters", Phonumbernames);
  const navigate = useNavigate();
  const flowkeyword = responseData.flow_details.start_keywors;
  const flownameupdate = responseData.flow_details.flow_name;
  const flowId = responseData.flow_details.flow_id;

  console.log("flow key ", flowkeyword);
  const initialNodes = [
    {
      id: "0",
      type: "flowKey",
      position: { x: Math.random() * 250, y: Math.random() * 250 },

      data: {
        onChange: (nodeId, inputType, value) => {
          setFlowKey((prevValues) => ({
            ...prevValues,
            [inputType]: value,
          }));
        },
        flowkey: flowkeyword,
      },
    },
  ];

  const [nodes, setNodes] = useState(initialNodes); // Start with an empty nodes array
  const [edges, setEdges] = useState([]);
  const [inputpdf, setInputpdf] = useState({});
  const [inputButton, setInputButton] = useState({});
  const [list, setInputList] = useState({});
  const [flowName, setFlowName] = useState(flownameupdate);
  const [flowKey, setFlowKey] = useState({});

  const [actionUrl, setActionUrl] = useState({});
  const [textarea, setTextArea] = useState({});
  const [mediaNode, setMediaNode] = useState({});
  const [interactiveList, setinteractiveList] = useState({});
  const [allMedia, setAllMedia] = useState({});
  const [catalogNode, setCatalogNode] = useState({});
  const [singleProductNode, setSingleProductNode] = useState({});
  const [multiProductListNode, setMultiProductListNode] = useState({});
  const [paymentGatewayNode, setPaymentGatewayNode] = useState({});
  const [paymentOrderDetailsNode, setPaymentOrderDetailsNode] = useState({});
  const [formdata, setFormdata] = useState({});
  const [googleForm, setGoogleForm] = useState({});
  const [timeDelay, setTimeDelay] = useState({});

  const [draggingEnabled, setDraggingEnabled] = useState(true);
  const sidebarRef = useRef(null);
  const extractConnectionData = () => {
    return edges.map((edge) => ({
      source_node_id: edge.source,
      button_id: edge.sourceHandle,
      target_node_id: edge.target,
      button_ids: edge.targetHandle,
    }));
  };

  const connectionData = extractConnectionData();

  const { message } = flowKey;
  const combinedArray = [
    { phone_number_id: responseData.phone_number_id },
    { Type: "flow_name", flow_start_keyword: message, flow_name: flowName },
    { Type: "interactiveCallToActionURLButtonMessages", data: actionUrl },
    { Type: "Connection", data: connectionData },
    { Type: "interactiveListMessages", data: interactiveList },
    { Type: "interactiveReplyButtonsMessages", data: mediaNode },
    { Type: "media", data: allMedia },
    { Type: "catalogMessages", data: catalogNode },
    { Type: "singleProductMessage", data: singleProductNode },
    { Type: "multiProductMessage", data: multiProductListNode },
    { Type: "text", data: textarea },
    { Type: "paymentsviaPaymentGateways", data: paymentGatewayNode },
    { Type: "paymentOrderDetailsNode", data: paymentOrderDetailsNode },
    { Type: "flowMessages", data: formdata },
    { Type: "timeDelay", data: timeDelay },
    { Type: "googleForm", data: googleForm },
  ];

  useEffect(() => {
    const nodeGap = 1000;
    const nodesPerRow = 20;

    const jsonArray = responseData.flow_data;

    const connectionsJson = responseData.connections[0];

    const flowkeyword = responseData.flow_details.start_keywors;

    const addMatchingNodesAndEdges = () => {
      // ==========================================================================

      // Find the object where Type is "text"
      const textTypeObject = jsonArray.filter((item) => item.Type === "text");
      const alltextbody = [];

      textTypeObject.forEach((textObject, index) => {
        console.log("text message ", index + 1);

        const textdatakeys = Object.keys(textObject.data);
        textdatakeys.forEach((key) => {
          const textdata =
            textObject?.data[key]?.textMessagePayload?.text?.body;
          alltextbody.push(textdata);
        });
      });

      // ==============================================================================
      const interactiveMessages = jsonArray.filter(
        (item) => item.Type === "interactiveReplyButtonsMessages"
      );

      const allBodyTexts = []; // Array to store each body text
      const allfooterText = [];
      const allmediaId = [];
      const allButtonText = [];

      // Loop through each filtered interactive message object
      interactiveMessages.forEach((replyObject, index) => {
        console.log(`Interactive Message ${index + 1}:`);

        // Get all keys in the data object (e.g., "1", "2")
        const replyDataKeys = Object.keys(replyObject.data);

        // Loop through each key in the data object
        replyDataKeys.forEach((key) => {
          const payload =
            replyObject?.data[key]?.payload?.interactive?.body?.text;
          const footerpayload =
            replyObject?.data[key]?.payload?.interactive?.footer?.text;
          const idPayload =
            replyObject?.data[key]?.payload?.interactive?.header?.image?.id;
          const initialButtons = replyObject?.data[
            key
          ]?.payload?.interactive?.action?.buttons?.map(
            (button) => button?.reply?.title
          );

          // Log the payload here directly if needed
          console.log("body text:", initialButtons);

          // Add payload to the array
          allBodyTexts.push(payload);
          allfooterText.push(footerpayload);
          allmediaId.push(idPayload);
          allButtonText.push(initialButtons);
        });
      });

      // Log all body texts after the loops
      console.log("All body texts:", allButtonText);

      //  =================================================================================================

      // Find the object where Type is "text"
      const CTATypeObject = jsonArray.filter(
        (item) => item.Type === "interactiveCallToActionURLButtonMessages"
      );
      const allCTAheader = [];
      const allCTAbody = [];
      const allCTAfooter = [];
      const allCTAbuttontext = [];

      CTATypeObject.forEach((CTAObject, index) => {
        console.log("CTA message ", index + 1);

        const CTAdatakeys = Object.keys(CTAObject.data);
        CTAdatakeys.forEach((key) => {
          const CTAheaderdata =
            CTAObject.data[key]?.actionpayload?.interactive?.header?.text;
          const CTAbodydata =
            CTAObject.data[key]?.actionpayload?.interactive?.body?.text;
          const CTAfooterdata =
            CTAObject.data[key]?.actionpayload?.interactive?.footer?.text;
          const CTAbuttonTextdata =
            CTAObject.data[key]?.actionpayload?.interactive?.action?.parameters
              ?.display_text;
          allCTAheader.push(CTAheaderdata);
          allCTAbody.push(CTAbodydata);
          allCTAfooter.push(CTAfooterdata);
          allCTAbuttontext.push(CTAbuttonTextdata);
        });
      });
      // ===============================================================================================
      // Find the object where Type is "text"
      const listTypeObject = jsonArray.filter(
        (item) => item.Type === "interactiveListMessages"
      );
      const allListHeader = [];
      const allListBody = [];
      const allListFooter = [];
      const allListButtonText = [];

      listTypeObject.forEach((listObject, index) => {
        console.log("List message ", index + 1);

        const listDataKeys = Object.keys(listObject.data);
        listDataKeys.forEach((key) => {
          const listHeaderData =
            listObject.data[key]?.Interactivepayload?.interactive?.header?.text;
          const listBodyData =
            listObject.data[key]?.Interactivepayload?.interactive?.body?.text;
          const listFooterData =
            listObject.data[key]?.Interactivepayload?.interactive?.footer?.text;
          const listButtonTextData =
            listObject.data[key]?.Interactivepayload?.interactive?.action
              ?.button;

          allListHeader.push(listHeaderData);
          allListBody.push(listBodyData);
          allListFooter.push(listFooterData);
          allListButtonText.push(listButtonTextData);
        });
      });

      // =============================================================================================
      // Find the object where Type is "text"
      const PayTypeObject = jsonArray.filter(
        (item) => item.Type === "paymentsviaPaymentGateways"
      );
      const allPaymentType = [];
      const allPaybody = [];
      const allPayfooter = [];
      const allPayExp = [];

      PayTypeObject.forEach((PayObject, index) => {
        console.log("Pay message ", index + 1);

        const Paydatakeys = Object.keys(PayObject.data);
        Paydatakeys.forEach((key) => {
          const PaymentType =
            PayObject.data[key]?.PaymentGatewayNode?.interactive?.action
              ?.parameters?.payment_settings?.[0]?.payment_gateway?.type;

          const Paybodydata =
            PayObject.data[key]?.PaymentGatewayNode?.interactive?.body?.text;
          const Payfooterdata =
            PayObject.data[key]?.PaymentGatewayNode?.interactive?.footer?.text;
          const PaybuttonExp =
            PayObject.data[key]?.PaymentGatewayNode?.interactive?.action
              ?.parameters?.expiration;

          allPaymentType.push(PaymentType);
          allPaybody.push(Paybodydata);
          allPayfooter.push(Payfooterdata);
          allPayExp.push(PaybuttonExp);
        });
      });
      // ====================================================================================
      // Find the object where Type is "text"
      const CatalogTypeObject = jsonArray.filter(
        (item) => item.Type === "catalogMessages"
      );

      const allCatalogbody = [];
      const allCatalogfooter = [];

      CatalogTypeObject.forEach((CatalogObject, index) => {
        console.log("Catalog message ", index + 1);

        const Catalogdatakeys = Object.keys(CatalogObject.data);
        Catalogdatakeys.forEach((key) => {
          const Catalogbodydata =
            CatalogObject.data[key]?.CatalogNodePayload?.interactive?.body
              ?.text;

          const Catalogfooterdata =
            CatalogObject.data[key]?.CatalogNodePayload?.interactive?.footer
              ?.text;

          allCatalogbody.push(Catalogbodydata);
          allCatalogfooter.push(Catalogfooterdata);
        });
      });
      // ==============================================================================================
      const SinglePrductTypeObject = jsonArray.filter(
        (item) => item.Type === "singleProductMessage"
      );

      const allSinglePrductbody = [];
      const allSinglePrductfooter = [];

      SinglePrductTypeObject.forEach((SinglePrductObject, index) => {
        console.log("SinglePrduct message ", index + 1);

        const SinglePrductdatakeys = Object.keys(SinglePrductObject.data);
        SinglePrductdatakeys.forEach((key) => {
          const SinglePrductbodydata =
            SinglePrductObject.data[key]?.SingleProductNodepayload?.interactive
              ?.body?.text;

          const SinglePrductfooterdata =
            SinglePrductObject.data[key]?.SingleProductNodepayload?.interactive
              ?.footer?.text;

          allSinglePrductbody.push(SinglePrductbodydata);
          allSinglePrductfooter.push(SinglePrductfooterdata);
        });
      });

      // =======================================================================================
      const MultiProductTypeObject = jsonArray.filter(
        (item) => item.Type === "multiProductMessage"
      );
      const allMultiProductheader = [];
      const allMultiProductbody = [];
      const allMultiProductfooter = [];

      MultiProductTypeObject.forEach((MultiProductObject, index) => {
        console.log("MultiProduct message ", index + 1);

        const MultiProductdatakeys = Object.keys(MultiProductObject.data);
        MultiProductdatakeys.forEach((key) => {
          const MultiProductheaderdata =
            MultiProductObject.data[key]?.MultiProductListNodepayload
              ?.interactive?.header?.text;

          const MultiProductbodydata =
            MultiProductObject.data[key]?.MultiProductListNodepayload
              ?.interactive?.body?.text;

          const MultiProductfooterdata =
            MultiProductObject.data[key]?.MultiProductListNodepayload
              ?.interactive?.footer?.text;

          allMultiProductheader.push(MultiProductheaderdata);
          allMultiProductbody.push(MultiProductbodydata);
          allMultiProductfooter.push(MultiProductfooterdata);
        });
      });

      // =======================================================================================
      const FormTypeObject = jsonArray.filter(
        (item) => item.Type === "flowMessages"
      );
      const allFormheader = [];
      const allFormbody = [];
      const allFormfooter = [];
      const allFormFlowToken = [];

      FormTypeObject.forEach((FormObject, index) => {
        console.log("Form message ", index + 1);

        const Formdatakeys = Object.keys(FormObject.data);
        Formdatakeys.forEach((key) => {
          const Formheaderdata =
            FormObject.data[key]?.Formdata?.interactive?.header?.text;

          const Formbodydata =
            FormObject.data[key]?.Formdata?.interactive?.body?.text;

          const Formfooterdata =
            FormObject.data[key]?.Formdata?.interactive?.footer?.text;

          const FormflowTokendata =
            FormObject.data[key]?.Formdata?.interactive?.action?.parameters
              ?.flow_token;

          allFormheader.push(Formheaderdata);
          allFormbody.push(Formbodydata);
          allFormfooter.push(Formfooterdata);
          allFormFlowToken.push(FormflowTokendata);
        });
      });

      // =============================================================================================
      const MediaTypeObject = jsonArray.filter((item) => item.Type === "media");
      const allMediabody = [];
      const allMediaId = [];

      MediaTypeObject.forEach((MediaObject, index) => {
        console.log("Media message ", index + 1);

        const Mediadatakeys = Object.keys(MediaObject.data);
        Mediadatakeys.forEach((key) => {
          const Mediadata =
            MediaObject.data[key]?.allMediapayload?.image?.caption;
          const MediaId = MediaObject.data[key]?.allMediapayload?.image?.id;

          allMediabody.push(Mediadata);
          allMediaId.push(MediaId);
        });
      });

      // ================================================================================================

      const connectionNodeTypes = new Set(
        connectionsJson.data.map((conn) => conn.button_ids)
      );

      // Filter jsonArray to include only nodes present in connectionNodeTypes
      const filteredNodes = jsonArray.filter((item) =>
        connectionNodeTypes.has(item.Type)
      );

      const newNodes = filteredNodes.map((item, index) => {
        const row = Math.floor(index / nodesPerRow);
        const col = index % nodesPerRow;
        // let count = 0;
        // const counter = () => {
        //   return count++;
        // };

        return {
          id: (nodes.length + index).toString(),
          type: item.Type,
          position: { x: col * nodeGap, y: row * nodeGap },
          data: {
            ...(item.Type === "text" && {
              onChange: handleTextArea,
              bodytext: alltextbody[0],
            }),

            ...(item.Type === "interactiveReplyButtonsMessages" && {
              onChange: handleMediaNode,
              replaybody: allBodyTexts[0],
              replayFooter: allfooterText[0],
              replayId: allmediaId[0],
              replaybuttons: allButtonText[0],
            }),

            ...(item.Type === "media" && {
              onChange: handleAllMedia,
              replaybody: allMediabody[0],
              replayId: allMediaId[0],
            }),
            ...(item.Type === "interactiveCallToActionURLButtonMessages" && {
              onChange: handleActionUrl,
              replayheader: allCTAheader[0],
              replaybody: allCTAbody[0],
              replayFooter: allCTAfooter[0],
              replaybuttontext: allCTAbuttontext[0],
            }),
            ...(item.Type === "interactiveListMessages" && {
              onChange: handleInteractiveList,
              replayheader: allListHeader[0],
              replaybody: allListBody[0],
              replayFooter: allListFooter[0],
              replaybuttontext: allListButtonText[0],
            }),
            ...(item.Type === "catalogMessages" && {
              onChange: handleCatelogpayload,

              replaybody: allCatalogbody[0],
              replayFooter: allCatalogfooter[0],
            }),
            ...(item.Type === "singleProductMessage" && {
              onChange: handlesingleProductNodepayload,
              replaybody: allSinglePrductbody[0],
              replayFooter: allSinglePrductfooter[0],
            }),
            ...(item.Type === "multiProductMessage" && {
              onChange: handlemultiProductListNodepayload,
              replayheader: allMultiProductheader[0],
              replaybody: allMultiProductbody[0],
              replayFooter: allMultiProductfooter[0],
            }),
            ...(item.Type === "paymentsviaPaymentGateways" && {
              onChange: handlepaymentGatewayNode,
              replayType: allPaymentType[0],
              replaybody: allPaybody[0],
              replayFooter: allPayfooter[0],
              replayPayExp: allCatalogfooter[0],
            }),
            ...(item.Type === "flowMessages" && {
              onChange: handleformdata,
              replayheader: allFormheader[0],
              replaybody: allFormbody[0],
              replayFooter: allFormfooter[0],
              replaytoken: allFormFlowToken[0],
            }),
            ...(item.Type === "timeDelay" && { onChange: handletimeDelay }),
            ...(item.Type === "googleForm" && { onChange: handlegoogleForm }),
            // Add other node-specific data handling as needed
          },
        };
      });

      // Mapping connections to edges only for nodes in filteredNodes
      const newEdges = connectionsJson.data.map((connection) => {
        return {
          id: `e${connection.source_node_id}-${connection.target_node_id}`,
          source: connection.source_node_id,
          target: connection.target_node_id,
          type: "customEdge",
          animated: true,
          sourceHandle: connection.button_id,
          targetHandle: connection.button_ids,
        };
      });

      // Set nodes and edges in state
      if (newNodes.length > 0) {
        setNodes((prevNodes) => [...prevNodes, ...newNodes]);
        setEdges((prevEdges) => {
          const existingEdgeIds = new Set(prevEdges.map((edge) => edge.id));
          const uniqueEdges = newEdges.filter(
            (edge) => !existingEdgeIds.has(edge.id)
          );
          return [...prevEdges, ...uniqueEdges];
        });
      }
    };

    addMatchingNodesAndEdges();
  }, []);

  const handleTextArea = (nodeId, inputType, value) => {
    setTextArea((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleMediaNode = (nodeId, inputType, value) => {
    setMediaNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleAllMedia = (nodeId, inputType, value) => {
    setAllMedia((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleActionUrl = (nodeId, inputType, value) => {
    setActionUrl((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleInteractiveList = (nodeId, inputType, value) => {
    setinteractiveList((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleCatelogpayload = (nodeId, inputType, value) => {
    setCatalogNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  const handlesingleProductNodepayload = (nodeId, inputType, value) => {
    setSingleProductNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handlemultiProductListNodepayload = (nodeId, inputType, value) => {
    setMultiProductListNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handlepaymentGatewayNode = (nodeId, inputType, value) => {
    setPaymentGatewayNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  const handleformdata = (nodeId, inputType, value) => {
    setFormdata((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handletimeDelay = (nodeId, inputType, value) => {
    setTimeDelay((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  const handlegoogleForm = (nodeId, inputType, value) => {
    setGoogleForm((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  const handleFlowName = (e) => {
    setFlowName(e.target.value);
  };
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) =>
      setEdges((eds) =>
        addEdge({ ...connection, type: "customEdge", animated: "true" }, eds)
      ),
    [setEdges]
  );

  const handleCommingSoon = () => {
    Swal.fire({
      icon: "success",
      title: "Comming Soon",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const toggleDraggingEnabled = () => {
    setDraggingEnabled(!draggingEnabled);
  };

  //-------------------------------------

  const handleSidebarTextArea = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "text",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handletextArea },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handletextArea = (nodeId, inputType, value, no) => {
    setTextArea((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarActionUrl = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "interactiveCallToActionURLButtonMessages",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleactionUrl },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleactionUrl = (nodeId, inputType, value) => {
    setActionUrl((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarMediaNode = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "interactiveReplyButtonsMessages",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handlemediaNode },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handlemediaNode = (nodeId, inputType, value) => {
    setMediaNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarInteractiveList = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "interactiveListMessages",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleinteractiveList },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleinteractiveList = (nodeId, inputType, value) => {
    setinteractiveList((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarAllMedia = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "media",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleallMedia },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleallMedia = (nodeId, inputType, value) => {
    setAllMedia((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  // --------------------------------product elements------------------

  const handleSidebarCatelogpayload = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "catalogMessages",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handlecatelogpayload },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handlecatelogpayload = (nodeId, inputType, value) => {
    setCatalogNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarSingleProductNodepayload = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "singleProductMessage",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleSingleProductNodepayload },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleSingleProductNodepayload = (nodeId, inputType, value) => {
    setSingleProductNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarMultiProductListNodepayload = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "multiProductMessage",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleMultiProductListNodepayload },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleMultiProductListNodepayload = (nodeId, inputType, value) => {
    setMultiProductListNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  //---------------------------------payment elements-------------------
  const handleSidebarPaymentGatewayNode = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "paymentsviaPaymentGateways",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handlePaymentGatewayNode },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handlePaymentGatewayNode = (nodeId, inputType, value) => {
    setPaymentGatewayNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  const handleSidebarpaymentOrderDetailsNode = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "paymentOrderDetailsNode",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handlepaymentOrderDetailsNode },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handlepaymentOrderDetailsNode = (nodeId, inputType, value) => {
    setPaymentOrderDetailsNode((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  // --------------------------------Form data--------------------
  const handleSidebarFormdata = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "flowMessages",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleFormdata },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleFormdata = (nodeId, inputType, value) => {
    setFormdata((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };

  // ---------------------------------Time delay--------
  const handleSidebarTimeDelay = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "timeDelay",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleTimeDelay },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleTimeDelay = (nodeId, inputType, value) => {
    setTimeDelay((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  // ---------------------------------Google form--------
  const handleSidebarGoogleForm = () => {
    const newNode = {
      id: nodes.length.toString(),
      type: "googleForm",
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { onChange: handleGoogleForm },
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Add this function to handle payment data updates
  const handleGoogleForm = (nodeId, inputType, value) => {
    setGoogleForm((prevValues) => ({
      ...prevValues,
      [nodeId]: {
        ...prevValues[nodeId],
        [inputType]: value,
      },
    }));
  };
  //------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("data:", JSON.stringify(combinedArray, null, 2));

    if (!flowKey) {
      // Show an alert if no flowKey is present
      Swal.fire({
        icon: "error",
        title: "Enter a value in the input fields",
        showConfirmButton: false,
        timer: 2000,
      });
      console.log("Enter a value");
      return;
    }

    try {
      console.log("flow id", flowId);
      const response = await fetch(`${baseUrl}/updataChatFlow`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: combinedArray, FlowId: flowId }),
      });

      const data = await response.json(); // Extract response body

      if (!response.ok) {
        console.log("Network response was not ok");
      }

      console.log("Success:", data.message);

      // Show the appropriate Swal alert based on the response
      Swal.fire({
        icon: data.success ? "success" : "error",
        title: data.success ? data.message : data.message,
        showConfirmButton: false,
        timer: 2000,
      });

      if (data.success) {
        navigate(`/?id=${Phonumbernames}&token=${token}`);
      }
    } catch (error) {
      console.error("Error:", error);

      // Show an error alert in case of an exception
      Swal.fire({
        icon: "error",
        title: "An error occurred. Please try again.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalMediaOpen, setIsModalMediaOpen] = useState(false);
  const [isModalListOpen, setIsModalListOpen] = useState(false);
  const [isModalCtaOpen, setIsModalCtaOpen] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalAllMediaOpen, setIsModalAllMediaOpen] = useState(false);
  const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);

  const handleOpenCarouselModal = () => {
    setIsCarouselModalOpen(true);
  };

  const handleCloseCarouselModal = () => {
    setIsCarouselModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModalMedia = () => {
    setIsModalMediaOpen(true);
  };

  const handleCloseModalMedia = () => {
    setIsModalMediaOpen(false);
  };

  const handleOpenModalCta = () => {
    setIsModalCtaOpen(true);
  };

  const handleCloseModalCta = () => {
    setIsModalCtaOpen(false);
  };

  const handleOpenModalList = () => {
    setIsModalListOpen(true);
  };

  const handleCloseModalList = () => {
    setIsModalListOpen(false);
  };

  const handleOpenModalAllMedia = () => {
    setIsModalAllMediaOpen(true);
  };

  const handleCloseModalAllMedia = () => {
    setIsModalAllMediaOpen(false);
  };

  const handleOpenModalForm = () => {
    setIsModalFormOpen(true);
  };

  const handleCloseModalForm = () => {
    setIsModalFormOpen(false);
  };

  const handleNodeSelect = (nodeType) => {
    const nodeActions = {
      catalogNode: handleSidebarCatelogpayload,
      SingleProductNode: handleSidebarSingleProductNodepayload,
      MultiProductListNode: handleSidebarMultiProductListNodepayload,
    };

    const action = nodeActions[nodeType];
    if (action) {
      action(); // Call the corresponding function if it exists
    }

    setProductModalOpen(false); // Close the modal after selection
  };

  const handleNodePaymentSelect = (nodeType) => {
    const nodeActions = {
      paymentGatewayNode: handleSidebarPaymentGatewayNode,
      paymentOrderDetailsNode: handleCommingSoon,
    };

    const action = nodeActions[nodeType];
    if (action) {
      action(); // Call the corresponding function if it exists
    }

    setPaymentModalOpen(false); // Close the modal after selection
  };
  const [isProductModalOpen, setProductModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const handleOpenProductModal = () => {
    setProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setProductModalOpen(false);
  };

  // ------------------------------------------------payment--------------
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const handleOpenPaymentModal = () => {
    setPaymentModalOpen(true);
  };

  const handleClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const [isPaymentCarouselModelOpen, setIsPaymentCarouselModalOpen] =
    useState(false);

  const handleOpenPaymentCarouselModal = () => {
    setIsPaymentCarouselModalOpen(true);
  };

  const handleClosePaymentCarouselModal = () => {
    setIsPaymentCarouselModalOpen(false);
  };

  // --------------------------------------------------------------------------------------------------------
  // =============================================================================================================

  return (
    <div className="flex h-screen w-full bg-white">
      <div
        ref={sidebarRef}
        className="bg-white  p-4 flex flex-col rounded-lg"
        style={{
          width: draggingEnabled ? "250px" : "20px",
          height: draggingEnabled ? "110%" : "90%",

          transition: "width 0.5s ease-in-out",
        }}>
        <div className="flex items-center mb-4  rounded-2xl">
          <button
            onClick={toggleDraggingEnabled}
            className="text-gray-600 hover:text-gray-800 focus:outline-none">
            {draggingEnabled ? (
              <MdOutlineKeyboardDoubleArrowLeft className="ml-8 text-black bg-white border-soild border-gray-200 border  shadow-lg rounded-full text-4xl" />
            ) : (
              <MdOutlineKeyboardDoubleArrowRight className="relative right-2  bg-white border-soild border-gray-200 border  bottom-1 right-7 text-4xl rounded-full text-black" />
            )}
          </button>
        </div>
        <div
          className={`flex-grow overflow-y-auto ${
            draggingEnabled ? "opacity-100" : "opacity-0"
          } transition-opacity`}>
          <div className="flex flex-wrap gap-4 pl-2 pr-2">
            {/* First Div - Text Message */}
            <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModal}
                  className="absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] mt-2 transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                title="Text Messages (Demo Look)"
                message="Text messages are messages containing only a text body and an optional link preview."
                image={textInfoImage}
                who="text"
              />

              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarTextArea}>
                <div className="relative inline-block">
                  <FaFacebookMessenger className="text-center ml-6 text-green-700" />

                  <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                    Text Message
                  </p>
                </div>
              </div>
            </div>

            {/* Second Div - Reply Message */}
            <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModalMedia}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px]  transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>
                <PopupModalMedia
                  isOpen={isModalMediaOpen}
                  onRequestClose={handleCloseModalMedia}
                  title="Interactive Reply Buttons Messages (Demo Look)"
                  message="Interactive reply buttons messages allow you to send up to three predefined replies for users to choose from.Users can respond to a message by selecting one of the predefined buttons, which triggers a messages webhook describing their selection."
                  image={replayimage}
                  who="replay"
                />

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>

              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarMediaNode}>
                <FaImage className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Reply Message
                </p>
              </div>
            </div>
            {/* Third Div - CTA Message */}
            <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModalCta}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModalCTA
                isOpen={isModalCtaOpen}
                onRequestClose={handleCloseModalCta}
                title="CTA Messages (Demo Look)"
                message="Your customers may be hesitant to tap raw URLs containing lengthy or obscure strings in text messages. In these situations, you may wish to send an interactive call-to-action (CTA) URL button message.CTA URL button messages allow you to map any URL to a button so you don't have to include the raw URL in the message body."
                image={cta}
                who="cta"
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarActionUrl}>
                <FaRegPaperPlane className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  CTA Message
                </p>
              </div>
            </div>
            {/* fourth Div - List Message */}
            <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModalList}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModalList
                isOpen={isModalListOpen}
                onRequestClose={handleCloseModalList}
                title="Interactive List Messages (Demo Look)"
                message="Interactive list messages allow you to present WhatsApp users with a list of options to choose from (options are defined as rows in the request payload), When a user taps the button in the message, it displays a modal that lists the options available"
                image={List}
                image2={List2}
                who="list"
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarInteractiveList}>
                <FaListAlt className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  List Message
                </p>
              </div>
            </div>
            {/* fifth Div -  Payment Type */}
            {/* <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenPaymentCarouselModal}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupPaymentCarousel
                isOpen={isPaymentCarouselModelOpen}
                onRequestClose={handleClosePaymentCarouselModal}
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleOpenPaymentModal}>
                <FaMoneyCheckAlt className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Payment Type
                </p>
              </div>
              <PopupPaymentModal
                isOpen={isPaymentModalOpen}
                onRequestClose={handleClosePaymentModal}
                onNodeSelect={handleNodePaymentSelect}
              />
            </div> */}
            {/* Sixth Div - Product Type */}
            <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenCarouselModal}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupCarouselModal
                isOpen={isCarouselModalOpen}
                onRequestClose={handleCloseCarouselModal}
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleOpenProductModal}>
                <AiFillProduct className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Product Type
                </p>
              </div>
              <PopupProductModal
                isOpen={isProductModalOpen}
                onRequestClose={handleCloseProductModal}
                onNodeSelect={handleNodeSelect}
              />
            </div>
            {/* Seventh Div - Media*/}
            <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModalAllMedia}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModalAllMedia
                isOpen={isModalAllMediaOpen}
                onRequestClose={handleCloseModalAllMedia}
                title="Media Message (Demo Look)"
                message=""
                image={allmedia}
                who="Media"
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarAllMedia}>
                <FaMobileScreenButton className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Media Message
                </p>
              </div>
            </div>
            {/* Eighth Div - Form Data*/}
            {/* <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModalForm}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[65px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModalForm
                isOpen={isModalFormOpen}
                onRequestClose={handleCloseModalForm}
                title="Form Message (Demo Look)"
                message=""
                image={form}
                who="Form"
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarFormdata}>
                <SiGoogleforms className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Form Data
                </p>
              </div>
            </div> */}
            {/* 9th Div - time delay Data*/}
            {/* <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModal}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[70px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                title="Text Messages (Demo Look)"
                message="Text messages are messages containing only a text body and an optional link preview."
                image={textInfoImage}
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarTimeDelay}>
                <IoTime className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Time Delay
                </p>
              </div>
            </div> */}
            {/* 10th Div - google form Data*/}
            {/* <div className="w-full sm:w-[calc(50%-0.5rem)]">
              <div className="relative group">
                <button
                  onClick={handleOpenModal}
                  className="mt-2 absolute bg-green-500 text-white rounded-full w-4 h-4 ms-[70px] transition-transform transform duration-300 ease-in-out hover:scale-110">
                  <FaInfo className="w-3 h-2 text-white ms-[2px]" />
                </button>

                <div className="absolute z-50 top-full mt-16 left-0 hidden group-hover:flex items-center justify-center bg-gray-700 text-white text-xs rounded px-2 py-1">
                  Text Message Demo Look
                </div>
              </div>
              <PopupModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                title="Text Messages (Demo Look)"
                message="Text messages are messages containing only a text body and an optional link preview."
                image={textInfoImage}
              />
              <div
                className="bg-white rounded-lg shadow-lg p-2 mt-3 cursor-pointer border-solid border-2 border-gray-200"
                onClick={handleSidebarGoogleForm}>
                <FaGoogle className="text-center ml-6 text-green-700" />
                <p className="text-center text-sm mt-1 font-poppins text-gray-600">
                  Google form
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="flex-grow  w-full " style={{ height: "87%" }}>
        <div className="flex  w-full h-14 bg-white border-dashed border-b border-gray-400">
          <div className="mb-2">
            <input
              type="text"
              className="  border-2 border-black border-solid mt-2 rounded-lg"
              placeholder={flownameupdate}
              required
              onChange={handleFlowName}
            />
          </div>
          <div className="ml-16">
            <button
              onClick={handleSubmit}
              className=" mt-1  bg-[#1d4c39] text-white px-8 py-3 hover:bg-green-800 rounded font-poppins  ">
              Save Flow
            </button>
          </div>

          <div className="ml-30"></div>
        </div>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          animated={true}
          minZoom={0}
          fitView
          fitViewOptions={{
            padding: 2.5,
          }}
          connectionLineComponent={ConnectionLine}>
          <MiniMap />
          <Controls />
          <Background
            size={3}
            gap={30}
            style={rfStyle}
            variant={BackgroundVariant.Dots}
          />
        </ReactFlow>
      </div>
    </div>
  );
}

export default Flow;

export const uploadMedia = async (file, type) => {
  const phoneNumberId = localStorage.getItem("phId") || "";
  const accesstoken = localStorage.getItem("a_token") || "";
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${accesstoken}` // Replace with your actual token
  );

  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("type", file.type);
  formdata.append("messaging_product", "whatsapp");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `https://graph.facebook.com/v21.0/${phoneNumberId}/media`,
      requestOptions
    );
    const result = await response.json();

    if (response.ok) {
      return result.id; // Return media ID on success
    } else {
      throw new Error("Upload failed");
    }
  } catch (error) {
    throw new Error("Error uploading media");
  }
};

import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { message } from "antd";
import "reactflow/dist/style.css";
import { uploadMedia } from "../../api/MediaID";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaImage, FaMoneyCheck } from "react-icons/fa";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

const PaymentGatewayNode = ({ id, data }) => {
  const replayType = data.replayType || "";
  const replaybody = data.replaybody || "";
  const replayfooter = data.replayFooter || "";
  const replayPayExp = data.replayPayExp || "";

  console.log("all open ", replayType, replaybody, replayfooter, replayPayExp);
  const [headerMediaId, setHeaderMediaId] = useState(null);
  const [bodyText, setBodyText] = useState(replaybody);
  const [footerText, setFooterText] = useState(replayfooter);
  const [paymentConfigId, setPaymentConfigId] = useState("");
  const [paymentType, setPaymentType] = useState(replayType || "BillDesk"); // Default value
  const [expirationTimestamp, setExpirationTimestamp] = useState(replayPayExp);
  const [mediaFile, setMediaFile] = useState(null);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [headerMediaPreview, setHeaderMediaPreview] = useState(null); // Image preview
  const [paymentConfigs, setPaymentConfigs] = useState([]); // Store the payment configurations here

  const fileInputRef = useRef(null);
  const { setNodes } = useReactFlow();

  const chaturl = process.env.Chat_API_URL;

  // Fetch payment configurations from API
  useEffect(() => {
    const fetchPaymentConfigurations = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          phone_number_id: "105581585784909", // Example phone_number_id
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${chaturl}getPaymentConfigurations`,
          requestOptions
        );
        const result = await response.json();

        if (result.payments && result.payments.length > 0) {
          // Assuming you want to store the list of configurations
          setPaymentConfigs(result.payments[0].payment_configurations || []);

          // Optionally, you can set the first configuration as default
          if (result.payments[0].payment_configurations.length > 0) {
            setPaymentConfigId(
              result.payments[0].payment_configurations[0].configuration_name
            );
          }
        }
      } catch (error) {
        console.error("Failed to fetch payment configurations", error);
      }
    };

    fetchPaymentConfigurations();
  }, []);

  // Validate the expiration timestamp to ensure it's at least 300 seconds from now
  const validateExpirationTimestamp = () => {
    const expirationTime = Number(expirationTimestamp);

    if (expirationTime < 300) {
      setError(
        "Expiration timestamp must be a valid UTC time and at least 300 seconds from now."
      );
      return false;
    }

    setError(""); // Clear any previous error
    return true;
  };

  const handleMediaUpload = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

      if (selectedFile.size > maxFileSize) {
        setError("File size exceeds 5MB. Please upload a smaller image.");
        return;
      }

      setError(""); // Clear any previous error
      setMediaFile(selectedFile);

      // Create and set image preview
      const previewUrl = URL.createObjectURL(selectedFile);
      setHeaderMediaPreview(previewUrl);

      try {
        const mediaId = await uploadMedia(selectedFile);
        setHeaderMediaId(mediaId);
        message.success("Media uploaded successfully");
      } catch (error) {
        message.error("Failed to upload media");
      }
    }
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleNodeDelete = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "PaymentGatewayNode", null); // Notify parent of text removal
    }
  };

  const generateOrderDetailsJSON = () => {
    return {
      interactive: {
        type: "order_details",
        header: {
          type: "image",
          image: {
            id: headerMediaId,
            provider: {
              name: "provider-name",
            },
          },
        },
        body: {
          text: bodyText,
        },
        footer: {
          text: footerText,
        },
        action: {
          name: "review_and_pay",
          parameters: {
            reference_id: "reference-id-value",
            type: "digital-goods",
            payment_settings: [
              {
                type: "payment_gateway",
                payment_gateway: {
                  type: paymentType,
                  configuration_name: paymentConfigId,
                  [paymentType]: {},
                },
              },
            ],
            currency: "INR",
            expiration: expirationTimestamp,
          },
        },
      },
    };
  };

  useEffect(() => {
    if (data.onChange && validateExpirationTimestamp()) {
      const orderDetailsPayload = generateOrderDetailsJSON();
      data.onChange(id, "PaymentGatewayNode", orderDetailsPayload);
    }
  }, [
    headerMediaId,
    bodyText,
    footerText,
    paymentConfigId,
    paymentType,
    expirationTimestamp,
    id,
    data,
  ]);

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="paymentsviaPaymentGateways"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaMoneyCheck className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Payment Gateway</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        {/* Header Image Upload */}
        {!headerMediaPreview ? (
          <>
            <label className="block p-4 text-3xl font-semibold text-gray-700 mb-3">
              Header Image
            </label>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleMediaUpload}
              accept="image/jpeg, image/png"
              style={{ display: "none" }}
            />
            <button
              onClick={() => fileInputRef.current.click()}
              className="flex flex-col items-center justify-center p-4 border w-full h-[250px] border-gray-300 rounded-lg shadow-lg bg-white">
              <FaImage className="text-5xl mb-4 text-black" />
              <p className="text-3xl text-gray-500">Upload Header Image</p>
            </button>
          </>
        ) : (
          <div className="mt-4">
            <img
              src={headerMediaPreview}
              alt="Header Preview"
              className="w-full h-[250px] object-cover rounded-lg"
            />
          </div>
        )}

        {/* Body Text Input */}
        <label className="block mt-4 text-3xl font-semibold text-gray-700 mb-2">
          Body Text (Required)
        </label>
        <textarea
          placeholder="Max 1024 characters"
          className="w-full text-3xl p-12 rounded-lg border-gray-300 shadow-sm text-center"
          value={bodyText}
          onChange={handleInputChange(setBodyText)}
          maxLength={1024}
        />
        <p className="text-right text-lg">{bodyText.length}/1024</p>

        {/* Footer Text Input */}
        <label className="block mt-4 text-3xl font-semibold text-gray-700 mb-2">
          Footer Text (Optional)
        </label>
        <textarea
          placeholder="Max 60 characters"
          className="w-full text-3xl p-6 rounded-lg border-gray-300 shadow-sm text-center"
          value={footerText}
          onChange={handleInputChange(setFooterText)}
          maxLength={60}
        />
        <p className="text-right text-lg">{footerText.length}/60</p>

        {/* Payment Type Select */}
        <label className="block mt-4 text-3xl font-semibold text-gray-700 mb-2">
          Payment Type
        </label>
        <select
          className="w-full p-6 text-3xl text-center border rounded-lg shadow-sm border-gray-300"
          value={paymentType}
          onChange={handleInputChange(setPaymentType)}>
          <option value="BillDesk" className="text-gray-700 text-sm">
            BillDesk
          </option>
          <option value="Razorpay" className="text-gray-700 text-sm">
            Razorpay
          </option>
          <option value="PayU" className="text-gray-700 text-sm">
            PayU
          </option>
          <option value="Zaakpay" className="text-gray-700 text-sm">
            Zaakpay
          </option>
        </select>

        {/* Payment Configuration Name Select */}
        {paymentConfigs.length > 0 && (
          <>
            <label className="block mt-12 text-3xl font-semibold text-gray-700 mb-2">
              Payment Configuration Name
            </label>
            <select
              className="w-full p-6 text-3xl text-center border rounded-lg shadow-sm border-gray-300"
              value={paymentConfigId}
              onChange={handleInputChange(setPaymentConfigId)}>
              {paymentConfigs.map((config) => (
                <option
                  className="text-gray-700 text-sm"
                  key={config.configuration_name}
                  value={config.configuration_name}>
                  {config.configuration_name}
                </option>
              ))}
            </select>
          </>
        )}

        {/* Expiration Timestamp Input */}
        <label className="block mt-12 text-3xl font-semibold text-gray-700 mb-2">
          Expiration Timestamp (UTC seconds)
        </label>
        <input
          type="text"
          placeholder="Expiration time in UTC seconds"
          className="w-full text-3xl p-6 rounded-lg border-gray-300 shadow-sm text-center"
          value={expirationTimestamp}
          onChange={handleInputChange(setExpirationTimestamp)}
        />
        {error && (
          <p className="text-red-500 text-center text-2xl mt-2">{error}</p>
        )}
      </div>

      {/* <Handle
        type="source"
        position={Position.Right}
        id="payment_order_node_out"
        style={hfStyle}
      /> */}
    </div>
  );
};

export default PaymentGatewayNode;
